import { Inject, Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import {
  IUser,
  CookieList,
  COOKIE_NAMES,
} from '@beneficity/authentication/types';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { LoginCookiesService } from './api/login-cookie.service';
import { MemberAccessibleService } from './api/member-accessible.service';
import { Brand } from '@beneficity/brand/types';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';
import { OamTokenAuthService } from './api/oam-token-auth.service';
import { LogoutService } from './api/logout.service';
import {
  decodeToken,
  getDecodedAccessToken,
  parseCookieValue,
} from '@beneficity/shared/util';

@Injectable()
export class AuthenticationService {
  /**
   * Build User from Authorize response
   * @param authResponse
   * @private
   */
  private static authorizeResponseToUser(authResponse: any): IUser {
    const ubkStr = authResponse?.payload?.applicableUbks[0];
    const ubkNum = parseInt(ubkStr, 10);
    return {
      memberId: authResponse?.payload?.memberId,
      username: authResponse?.payload?.loginId,
      role: 'MEMBER',
      ubk: ubkNum,
      applicableUBKs: [ubkNum],
      firstName: authResponse?.payload?.firstName?.toLowerCase(),
      lastName: authResponse?.payload?.lastName?.toLowerCase(),
      groupNumber: authResponse?.payload?.groupNumber,
      mbrClientNumber: authResponse?.payload?.clientNumber,
      mbrGroupEffectiveDate: authResponse?.payload?.coverageEffectiveDate,
      planName: authResponse?.payload?.planName,
      forcedPassword:
        authResponse?.payload?.securityStrengthEnforcement?.toUpperCase() ===
        'FORCED',
      isTempPassword: false,
      avatarUrl: '',
      brand: '',
      isSalesDemo: authResponse?.payload?.salesDemo,
    };
  }

  /**
   * Pull UBK from Authorize Response
   * @param authorizeResponse
   * @private
   */
  private static getUserBrandFromAuthResponse(authorizeResponse: any): number {
    const ubk = authorizeResponse?.payload?.applicableUbks[0];
    return Number.parseInt(ubk, 10);
  }

  /**
   * Pull the required Member cookie values from the authorization response
   * @param authorizeResponse
   * @param userBrand
   */
  private static getMemberCookies(authorizeResponse: any, userBrand: Brand) {
    const brandCode = userBrand?.umsBrandCode
      ? userBrand.umsBrandCode
      : userBrand.sharedBrandCode;

    const memberCookies: CookieList = {
      umsBrand: brandCode?.toUpperCase(),
      mbrGroupNumber: authorizeResponse?.payload?.groupNumber,
      mbrClientNumber: authorizeResponse?.payload?.clientNumber,
      mbrGroupEffectiveDate: authorizeResponse?.payload?.coverageEffectiveDate,
      emkt_ubk: userBrand?.ubk.toString(),
    };

    if (authorizeResponse?.payload?.salesDemo) {
      memberCookies.DDValue = encodeURIComponent(btoa('50'));
    }

    if (authorizeResponse?.payload?.medicalCoverage) {
      memberCookies.emkt_lob = 'Medical';
    }
    return memberCookies;
  }

  /**
   * Maps valid override properties into org defined cookies.
   * @param overrides
   * @returns
   */
  private static mapOverridesToCookieVals(overrides: Record<string, string>) {
    const overrideEntries =
      Object.entries(overrides)?.length > 0 ? Object.entries(overrides) : null;
    if (Boolean(overrideEntries)) {
      const overrideCookies: CookieList = {};
      overrideEntries.map(([k, v]) => {
        if (COOKIE_NAMES[k] && Boolean(v)) {
          overrideCookies[COOKIE_NAMES[k]] = v;
        }
      });
      return overrideCookies;
    } else {
      return null;
    }
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly loginCookieService: LoginCookiesService,
    private readonly memberAccessibleService: MemberAccessibleService,
    private readonly oamTokenAuthService: OamTokenAuthService,
    private readonly logoutService: LogoutService,
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig
  ) {}

  /**
   * Entry point when we get an Access Token
   * @param accessToken
   */
  public authenticate(
    accessToken: string,
    defaultOverrideValues?: Record<string, string>
  ): Observable<[IUser, Record<string, unknown>, string, Brand, CookieList]> {
    return this.validateToken(accessToken, defaultOverrideValues);
  }

  /**
   * Public method for overwriting cookies if non-default values are required
   * @param cookies
   * @returns
   */
  public updateCookies(cookies: Record<string, string>): Observable<any> {
    return forkJoin([
      of(cookies),
      this.loginCookieService.setCookies(JSON.stringify(cookies)),
    ]);
  }

  /**
   * Validate the Access Token
   * Pull legacy token and call login cookie service to set LtpaToken cookie header
   * Call Authorize Member to get member attributes and cookies.
   * @param accessToken
   * @private
   */
  private validateToken(
    accessToken: string,
    defaultOverrideValues?: Record<string, string>
  ): Observable<[IUser, Record<string, unknown>, string, Brand, CookieList]> {
    const tokenInfo = getDecodedAccessToken(accessToken);
    const tokenDomain = tokenInfo.domain;

    // console.log('About to validate access token');

    return this.httpClient
      .get(
        `https://${this.envConfig.serviceDomains.oam}/api/oauth/v4/token/info?access_token=${accessToken}`,
        {
          headers: {
            Accept: 'application/json',
            'X-OAUTH-IDENTITY-DOMAIN-NAME': tokenDomain,
          },
        }
      )
      .pipe(
        switchMap((attrs) =>
          // callled oamTokenAuthService to obtain the OAM ID,
          // this is new endpoint provided from OAM team
          combineLatest([
            this.oamTokenAuthService.fetch(accessToken, attrs),
            of(attrs),
          ])
        ),
        switchMap(([oamId, attributes]) =>
          this.processLegacyToken(
            oamId as Record<string, unknown>,
            attributes as Record<string, unknown>
          )
        ),
        switchMap(([attributes, cookies, _]) =>
          this.authorizeMember(
            accessToken,
            attributes,
            cookies,
            defaultOverrideValues
          )
        ),
        switchMap(([user, attributes, brand, cookies]) =>
          forkJoin([
            of(user),
            of(attributes),
            of(accessToken),
            of(brand),
            of(cookies),
          ])
        )
      );
  }

  /**
   * Pull the LtpaToken2 cookie from the access token attributes.
   * Pass token to the login cookie service to attach token as a Cookie header.
   * @param attributes
   * @param OAM_ID which is passed to setCookie method
   * @private
   */
  private processLegacyToken(
    oamId: Record<string, unknown>,
    attributes: Record<string, unknown>
  ): Observable<[Record<string, unknown>, Record<string, string>, any]> {
    const cookies = {
      LtpaToken2: parseCookieValue(
        decodeToken(attributes?.['LtpaToken2'] as string)
      ),
      OAM_ID: atob(oamId?.['OAM_ID'] as string),
    };
    return forkJoin([
      of(attributes),
      of(cookies),
      this.loginCookieService.setCookies(JSON.stringify(cookies)),
    ]);
  }

  /**
   * Call authorize member to get member attributes.
   * Pull attributes and call member cookie service to attach as Cookie headers.
   * @param attributes
   * @param authCookies
   * @private
   */
  private authorizeMember(
    accessToken: string,
    attributes: Record<string, unknown>,
    authCookies: Record<string, string>,
    defaultOverrideValues?: Record<string, string>
  ): Observable<[IUser, Record<string, unknown>, Brand, CookieList]> {
    return this.memberAccessibleService
      .fetch(accessToken, defaultOverrideValues)
      .pipe(
        switchMap((authorizeResponse) => {
          return forkJoin([
            of(authorizeResponse),
            this.getBrand(
              AuthenticationService.getUserBrandFromAuthResponse(
                authorizeResponse
              )
            ),
          ]);
        }),
        switchMap(([authorizeResponse, brand]) => {
          let cookies = AuthenticationService.getMemberCookies(
            authorizeResponse,
            brand
          );
          // Cookie object assembled in priority order auth > default > member

          if (Boolean(defaultOverrideValues)) {
            cookies = {
              ...cookies,
              ...AuthenticationService.mapOverridesToCookieVals(
                defaultOverrideValues
              ),
              ...authCookies,
            };
          } else {
            cookies = {
              ...cookies,
              ...authCookies,
            };
          }
          // Call backend to validate and set member cookies as cookie headers
          return forkJoin([
            of(authorizeResponse),
            of(brand),
            of(cookies),
            this.loginCookieService.setCookies(JSON.stringify(cookies)),
          ]);
        }),
        switchMap(([authorizeResponse, brand, cookies]) => {
          return forkJoin([
            of(
              AuthenticationService.authorizeResponseToUser(authorizeResponse)
            ),
            of(attributes),
            of(brand),
            of(cookies),
          ]);
        })
      );
  }

  public getBrand(ubk: number): Observable<Brand> {
    const brandContentBasePath = '/assets/content/brand/';
    const brandValuesUrl = `${brandContentBasePath}${ubk}.json`;

    return this.httpClient.get(brandValuesUrl);
  }

  /**
   *
   * @param accessToken
   */
  // public refreshOamSession(accessToken: string): Observable<any> {
  //   // console.log('Refresh OAM Token Attempt');
  //   return this.oamTokenAuthService.fetch(accessToken);
  // }

  public logout(domain): Observable<any> {
    return combineLatest([
      this.logoutService.logoutByDomain(domain),
      this.logoutService.logout(),
    ]);
  }
}
