import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfig } from '@beneficity/environment/types';
import { AxwayHttpClient } from '@beneficity/shared/util';
import { ServiceProperties } from '@beneficity/shared/types';

@Injectable()
export class OamTokenAuthService {
  serviceProperties: ServiceProperties;

  constructor(
    private readonly http: AxwayHttpClient,
    @Inject(ENV_CONFIG) protected readonly envConfig: EnvConfig
  ) {
    this.serviceProperties = {
      url: '/secureoauth/v1/ext/user/getOamCookie',
    };
  }

  fetch(accessToken: string, attrs: any): Observable<any> {
    // Sending dummy payload to the service because the body parameter requirement is not from backend api,
    // it is a requirement from api manager, backend api discards what we pass in username request body parameter
    const payload = {
      username: attrs['uid'],
    };
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${accessToken}`,
      envname: this.envConfig.production ? 'prod' : 'test',
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.http.post(this.serviceProperties, payload, headers);
  }
}
