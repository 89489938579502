import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthenticationActions from './authentication.actions';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { of } from 'rxjs';
import { Action, select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { getAccessToken } from './authentication.selectors';
import { timeoutWhen } from '@beneficity/shared/util';
import { TIMEOUT_ERR } from '@beneficity/authentication/types';

@Injectable()
export class AuthenticationEffects {
  authFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthenticationActions.authenticateFailure),
        tap((action) => {
          // 401 errors are handled globally by error-interceptor.ts
          if (action.error?.name === TIMEOUT_ERR) {
            this.router.navigate(['/unauthorized']);
          } else if (action.error?.status !== 401) {
            this.router.navigate(['/error']);
          }
        })
      ),
    { dispatch: false }
  );

  // authSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthenticationActions.authenticateSuccess),
  //     switchMap((action) => {
  //       const sideEffects: any[] = [AuthenticationActions.oamSessionRefresh()];
  //       return sideEffects;
  //     })
  //   )
  // );

  // oamSessionRefresh$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthenticationActions.oamSessionRefresh),
  //     withLatestFrom(this.store$.pipe(select(getAccessToken))),
  //     switchMap(([action, token]) =>
  //       this.authService.refreshOamSession(token).pipe(
  //         map(() => {
  //           // console.log('OAM Refresh Success');
  //           return AuthenticationActions.oamSessionRefreshSuccess();
  //         }),
  //         catchError((error) => {
  //           console.log('OAM Refresh Failure', error);
  //           return of(
  //             AuthenticationActions.oamSessionRefreshFailure({
  //               error: JSON.stringify(error),
  //             })
  //           );
  //         })
  //       )
  //     )
  //   )
  // );

  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.authenticate),
      switchMap((action) =>
        this.authService
          .authenticate(action.accessToken, action.defaultOverrideValues)
          .pipe(
            timeoutWhen(30000),
            switchMap(([user, attributes, accessToken, brand, cookies]) => {
              // console.log('Authenticate Success');
              const sideEffects: Array<Action> = [
                AuthenticationActions.authenticateSuccess({
                  user,
                  accessToken,
                  attributes,
                  brand,
                  cookies,
                }),
              ];
              return sideEffects;
            }),
            catchError((error) => {
              console.log('Authenticate Error', error);
              return of(AuthenticationActions.authenticateFailure({ error }));
            })
          )
      )
    );
  });

  logoutHmk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logout),
      tap((action) => {
        // Temporary code to isolate requests to RBSMBL logout service.
        // Successful requests should execute as planned, and errors are ignored.
        try {
          this.authService.logout(action.domain).pipe(take(1)).subscribe();
        } catch (e) {
          //Temporary: Ignore errors
          console.error({ e });
        }
      }),
      // Default to logout success.
      map((_) => AuthenticationActions.logoutSuccess()),
      catchError((error) => {
        console.error({ error });
        return of(AuthenticationActions.logoutFailure({ error }));
      })
    )
  );

  updateCookies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.updateCookies),
      switchMap((action) => this.authService.updateCookies(action.cookies)),
      map(([cookies, _]) =>
        AuthenticationActions.updateCookiesSuccess({ cookies })
      ),
      catchError((error) => {
        console.error({ error });
        return of(AuthenticationActions.updateCookiesFailure({ error }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private readonly router: Router,
    private readonly authService: AuthenticationService
  ) {}
}
