import { IUser } from './user.types';

export type AuthStatusType = 'initial' | 'loading' | 'success' | 'error';

export class AuthState {
  status: AuthStatusType;
  user?: IUser;
  accessToken?: string;
  error?: string;
}
export interface CookieList {
  ltpaToken2?: string;
  oamId?: string;
  umsBrand?: string;
  mbrGroupNumber?: string;
  mbrClientNumber?: string;
  mbrGroupEffectiveDate?: string;
  emkt_lob?: string;
  emkt_ubk?: string;
  DDValue?: string;
  emkt_userName?: string;
}

export const COOKIE_NAMES: Record<string, string> = {
  clientNumber: 'mbrClientNumber',
  ubk: 'emkt_ubk',
  brand: 'umsBrand',
  groupNumber: 'mbrGroupNumber',
  effectiveDate: 'mbrGroupEffectiveDate',
  lob: 'emkt_lob',
  emkt_userName: 'emkt_userName',
};

export const LTPA_COOKIE = 'LtpaToken2';

export const TIMEOUT_ERR = 'TimeoutError';
